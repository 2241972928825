<template lang="pug">
.team(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  .team_info.pt-10
    v-container.align-center.px-3
      v-row.team_mobile.d-sm-none.mx-0
        .team_logo.temp_logo_hidden
          img(:src="teamLogo")
        .team_title.ml-5
          h5 {{ teamName }}
          span.text-subtitle-1.text_light_gray {{ teamRank }} | {{ winLoseCount }}
      v-row.mx-0
        .team_logo.d-none.d-sm-block.temp_logo_hidden
          img(:src="teamLogo")
        .team_content
          h5.d-none.d-sm-inline-block {{ teamName }}
          span.d-none.d-sm-inline-block.ml-2.text-subtitle-1.text_light_gray {{ teamRank }} | {{ winLoseCount }}
          .board.d-flex.mt-7
            .board_item(v-for="(item, index) in teamInfo", :key="index")
              h6.text_light_gray {{ item.title }}
              h4 {{ item.ranking }}
              p {{ item.value }}
                span(v-if="index < 2") /單場平均

    v-container.mt-7.pa-0(fluid)
      v-sheet(color="grey lighten-3")
        v-container.pa-0
          v-tabs.sheet(
            v-model="pageIndex",
            background-color="grey lighten-3",
            ripple="false",
            show-arrows
          )
            v-tabs-slider(hidden="true")

            v-tab(
              @click="toPage(index)",
              v-for="(tabItem, index) in tabList",
              :key="index",
              :ripple="false"
            ) {{ tabItem }}

    .top-dropdown
      v-container
        v-row(justify="center")
          v-col.select_label
            span.text-body-2 選擇賽季：
          v-col.clear(cols="auto", style="padding: 0")
            select.selector(
              name="selectSeasonIndex",
              v-model="selectSeasonIndex"
            )
              option(:value="index", v-for="(item, index) in teamSeasons") {{ item.name }}

  v-main
    transition(name="page", mode="out-in")
      router-view(@getTeamData="getTeamData")
  custom-footer
</template>

<script>
import CustomFooter from "@/components/common/Footer";
import { getTeamSeasonData, getTeamSeasonList } from "@/api/team";
import { getObjIdxById } from "@/utils/util";

export default {
  name: "LeagueTeam",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    CustomFooter,
  },
  data() {
    return {
      leagueID: this.$route.params.league,
      seasonID: this.$route.params.season,
      teamID: this.$route.params.id,
      leagueData: [],
      teamSeasons: [],
      teamSeasonData: [],
      selectSeasonIndex: 0,
      teamLogo: "",
      teamName: "",
      teamRank: "",
      winLoseCount: "",
      teamInfo: [],
      pageIndex: -1,
      // tabList: ["戰績", "數據", "進階數據", "陣容"],
      tabList: ["戰績", "數據", "陣容"],
      //TODO: siteTitle
      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        default_title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  watch: {
    async selectSeasonIndex() {
      if (this.selectSeasonIndex != -1) {
        await this.getTeamSeasonData();
        this.toPage(this.pageIndex);
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      this.setPage(this.$route.name);
      await this.getTeamSeasonList();
      await this.getTeamSeasonData();
    },
    async getTeamSeasonList() {
      let temp = {
        league_id: this.leagueID,
        team_id: this.teamID,
      };
      await getTeamSeasonList(temp).then((response) => {
        this.teamSeasons = response.data;

        var index = getObjIdxById(this.seasonID, response.data);
        if (index != -1) {
          this.selectSeasonIndex = index;
        } else {
          this.selectSeasonIndex = 0;
        }
      });
    },
    async getTeamSeasonData() {
      let temp = {
        season_id: this.teamSeasons[this.selectSeasonIndex].id,
        part: "info,ranking",
        team_id: this.teamID,
      };
      await getTeamSeasonData(temp).then((response) => {
        this.teamSeasonData = response.data.summary;
        this.metaData.title =
          this.teamSeasonData.info.name + " | " + this.metaData.default_title;
        this.teamName = this.teamSeasonData.info.name;
        this.teamLogo = this.teamSeasonData.info.photo.image;
        this.teamRank = this.getRankText(
          this.teamSeasonData.ranking.win_rate.ranking
        );
        this.winLoseCount =
          this.teamSeasonData.info.wins + "-" + this.teamSeasonData.info.losses;
        this.teamInfo = [];

        let score = {
          title: "得分",
          ranking: this.getRankText(
            this.teamSeasonData.ranking.avg_pts.ranking
          ),
          value: this.teamSeasonData.ranking.avg_pts.value.toFixed(1),
        };
        this.teamInfo.push(score);
        let block = {
          title: "籃板",
          ranking: this.getRankText(
            this.teamSeasonData.ranking.avg_reb.ranking
          ),
          value: this.teamSeasonData.ranking.avg_reb.value.toFixed(1),
        };
        this.teamInfo.push(block);
        let fieldGoal = {
          title: "投籃命中",
          ranking: this.getRankText(
            this.teamSeasonData.ranking.avg_fg_percent.ranking
          ),
          value:
            (this.teamSeasonData.ranking.avg_fg_percent.value * 100).toFixed(
              1
            ) + "%",
        };
        this.teamInfo.push(fieldGoal);
        let threePoint = {
          title: "三分命中率",
          ranking: this.getRankText(
            this.teamSeasonData.ranking.avg_three_pt_fg_percent.ranking
          ),
          value:
            (
              this.teamSeasonData.ranking.avg_three_pt_fg_percent.value * 100
            ).toFixed(1) + "%",
        };
        this.teamInfo.push(threePoint);
      });
    },
    getTeamData(teamId) {
      this.teamID = teamId;
      this.initData();
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
    setPage(name) {
      switch (name) {
        case "LeagueTeamRecords":
          this.pageIndex = 0;
          break;
        case "LeagueTeamStatistics":
          this.pageIndex = 1;
          break;
        // case "LeagueTeamAdvancedStatistics":
        //   this.pageIndex = 2;
        //   break;
        case "LeagueTeamLineUp":
          this.pageIndex = 2;
          break;
      }
    },
    toPage(index) {
      this.pageIndex = index;

      switch (index) {
        case 0:
          this.$router.push({
            name: "LeagueTeamRecords",
            params: {
              season: this.teamSeasons[this.selectSeasonIndex].id,
            },
          });
          break;
        case 1:
          this.$router.push({
            name: "LeagueTeamStatistics",
            params: {
              season: this.teamSeasons[this.selectSeasonIndex].id,
            },
          });
          break;
        //TODO case 2:
        // this.$router.push({
        //   name: "LeagueTeamAdvancedStatistics",
        //   params: {
        //     season: this.teamSeasons[this.selectSeasonIndex].id,
        //   },
        // });
        // break;
        case 2:
          this.$router.push({
            name: "LeagueTeamLineUp",
            params: {
              season: this.teamSeasons[this.selectSeasonIndex].id,
            },
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.board_item {
  width: 150px;

  h6 {
    font-size: 18px;
    color: $deepGray;
  }

  h4 {
    color: $primary;
    font-weight: bold;
    font-style: italic;
    font-family: Helvetica;
    font-size: 34px;
    line-height: 50px;
  }

  p {
    font-size: 14px;
    color: $lightGray;

    span {
      font-weight: normal;
      font-size: 12px;
    }
  }
}

.team_logo {
  width: 180px;
  height: auto;

  img {
    width: 100%;
    height: 100%;
  }
}

.team_content {
  margin-left: 60px;
  font-size: 24px;
  font-weight: bold;
  flex-shrink: 1;
  flex-grow: 1;
}

@media (max-width: 959px) {
  .board_item {
    width: 25%;
    flex-shrink: 1;

    h6 {
      font-size: 14px;
    }

    h4 {
      font-size: 28px;
      line-height: 34px;
    }
  }

  .team_logo {
    width: 20vw;

    flex-shrink: 0;
  }

  .team_content {
    margin-left: 10vw;

    h5 {
      font-size: 18px;
    }
  }
}

@media (max-width: 599px) {
  .team_title {
    font-size: 20px;
  }

  .team_content {
    margin-left: 0;
  }

  .board {
    flex-wrap: wrap;
  }

  .board_item {
    padding-right: 5px;
    width: 50%;

    h4 {
      font-size: 24px;
      line-height: 30px;
      display: inline-block;
    }

    p {
      font-size: 16px;
      display: inline-block;
      margin-left: 10px;
    }
  }
}
</style>
