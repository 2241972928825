export function getObjIdxById(id, obj_list) {
  if (id) {
    var i;
    for (i = 0; i < obj_list.length; i++) {
      if (obj_list[i].id.toString() === id.toString()) {
        return i;
      }
    }
  }
  return -1;
}

export function get(object, key, default_value) {
  if (key in object) {
    return object[key];
  } else {
    return default_value;
  }
}
